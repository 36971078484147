import React, { useState, useEffect } from "react";
import { CSpinner } from "@coreui/react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import { auth, functions, firestore } from "./firebase";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const _ = require('lodash');

const allTexts = require("./texts.json");
var db = require("./DBManager.js");

const loadingSpinner = (
  <div
    style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
    className="d-flex flex-column justify-content-between content-center"
  >
    <div />
    <CSpinner />
    <div />
  </div>
);

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
//await sleep(5000);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

let popupCallback = function () { };

export default function App() {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [loginError, setLoginError] = useState([""]);
  const [registerError, setRegisterError] = useState("");
  const [texts, setTexts] = useState(allTexts.es);
  const [logs, setLogs] = useState();
  const [users, setUsers] = useState();
  const [station, setStation] = useState();
  const [stations, setStations] = useState();
  const [mergedStations, setMergedStations] = useState();
  const [stationIds, setStationIds] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateDimensions = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  const pageRoleAccesList = {
    "stations": ['superadmin', 'admin', 'manager'],
    "settings": ['superadmin', 'admin', 'manager'],
    "users": ['superadmin', 'admin', 'manager'],
    "administrators": ['superadmin', 'admin', 'manager']
  }

  function hasAcces(page) {
    return (pageRoleAccesList[page] && pageRoleAccesList[page].includes(user.role));
  }

  function resetLock(lock) {
    const stationId = lock.stationId;
    delete lock.stationId;
    delete lock.stationName;

    functions
      .httpsCallable("resetLock")({
        stationId,
        lock
      })
      .catch((e) => console.log("resetLock ERROR:" + e));
  }

  async function setStationConfig(config) {
    delete config.HISTORY;
    delete config.KEYS;
    delete config.LOCKS;
    config.action = "update";

    try {
      const callTest = await functions.httpsCallable("setStationConfig")({ stationId: config.id, config });
      console.log("response: ", callTest.data.message);
      return callTest.data;
    }
    catch (e) {
      console.log("response: ", e);
    }
  }

  function openLock(lock) {
    functions
      .httpsCallable("openLock")({
        stationId: lock.stationId,
        lock
      })
      .catch((e) => console.log("openLock ERROR:" + e));
  }

  async function rebootStation(stationId) {
    try {
      const callTest = await functions.httpsCallable("rebootStation")({ stationId });
      console.log("response: ", callTest.data.message);
      return callTest.data;
    }
    catch (e) {
      console.log("response: ", e);
    }
  }

  async function updateUsers(users) {
    try {
      let updateUsers = JSON.parse(JSON.stringify(users)).map(u => {
        u.stations = u.stations.map(st => {
          if (st.type === 'stations') return st.ids[0];
        })
        return u;
      });

      const callTest = await functions.httpsCallable("update_users")({ users: updateUsers });
      console.log("response: ", callTest.data);

      if (callTest.data.usersError?.length > 0)
        return { message: callTest.data.usersError[0].error };
      else
        return callTest.data;
    }
    catch (e) {
      console.log("response: ", e);
      return { message: "No user updated due to: Unexpected error" };
    }
  }

  async function deleteUsers(users) {
    try {
      const callTest = await functions.httpsCallable("delete_users")({ users });
      console.log("response: ", callTest.data);

      if (callTest.data.usersError?.length > 0)
        return { message: callTest.data.usersError[0].error };
      else
        return callTest.data;
    }
    catch (e) {
      console.log("response: ", e);
      return { message: "No user deleted due to: Unexpected error" };

    }
  }

  async function addUsers(users) {
    try {
      let updateUsers = JSON.parse(JSON.stringify(users)).map(u => {
        u.stations = u.stations.map(st => {
          return st.id ?? st;
        })
        return u;
      });

      const callTest = await functions.httpsCallable("add_users")({ users: updateUsers });
      console.log("response: ", callTest.data);

      if (callTest.data.usersAdded?.length > 0)
        return callTest.data;
      else
        return { message: callTest.data?.usersError?.[0]?.error ?? "No new user created due to: Unexpected error" };
    }
    catch (e) {
      console.log("response: ", e);
      return { message: "No new user created due to: Unexpected error" };
    }
  }

  function mergeStations() {
    let tmpStationIds = stationIds?.find((st) => st.name === station?.name).ids;
    let totalDeposits = 0, abnormalLocks = 0, totalLocks = 0;
    let tmpStadisitcs = { STATIONS: [] };

    stations
      .filter((s) => tmpStationIds?.includes(s.id))
      .forEach((st) => {
        //COUNT TOTAL DEPOSITS PER LOCK AND CHECK IF ABNORMAL
        st.LOCKS?.forEach((lock) => {
          totalLocks++;
          totalDeposits += lock.deposits;
          if (!["free", "in_use", "control"].includes(lock.status))
            abnormalLocks++;
        });

        //AFEGIR LOGS
        st.LOGS = logs?.find(log => log.id === st.id);

        tmpStadisitcs.STATIONS.push(st);
      });
    tmpStadisitcs.totalUsers = users?.length;
    tmpStadisitcs.totalDeposits = totalDeposits;
    tmpStadisitcs.abnormalLocks = abnormalLocks;
    tmpStadisitcs.totalLocks = totalLocks;
    tmpStadisitcs.users = users?.filter(u => u.stations.some(us => tmpStationIds?.includes(us.ids[0])));

    setMergedStations(tmpStadisitcs);
  }

  async function changeStationSelected(station) {
    setLoading(true);
    setStation(station);
    await db.initStations(firestore, dbchangeStations, station.ids);
    await db.initLogs(firestore, dbchangeLogs, station?.ids);
    await db.initUsers(firestore, dbchangeUsers, station?.ids);
    setLoading(false);
  }

  async function dbchangeStations(data) {
    let stationsTmp = [];
    data.forEach((st) => {
      let stationTmp = st.data();
      stationsTmp.push(stationTmp);
    });

    if (!_.isEqual(stations, stationsTmp))
      setStations(stationsTmp);
  }

  async function dbchangeLogs(data) {
    let stationsTmp = [];
    data.forEach((st) => {
      let stationTmp = st.data();
      stationsTmp.push(stationTmp);
    });

    if (!_.isEqual(logs, stationsTmp))
      setLogs(stationsTmp);
  }

  async function dbchangeUsers(data) {
    if (!_.isEqual(users, data))
      setUsers(data);
  }

  async function handleRegister(email, password) {
    try {
      let userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      let user = JSON.parse(JSON.stringify(userCredential.user));
      setUser(user);
      setRegisterError("");
    } catch (error) {
      console.log("<handleRegister> error: " + error.message);
      setRegisterError(error.message);
    }
  }

  async function handleLogin(email, password) {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setLoginError(error.message);
        console.log("<handleLogin> error: " + error.message);
      });
    setLoading(false);
  }

  async function handleLogout() {
    auth
      .signOut()
      .then(() => {
        //console.log("<handleLogin> Sign-out successful.");
        setUser();
      })
      .catch((error) => {
        console.log("<handleLogin> error: " + error.message);
      });
  }

  function popup(caption, callback, title = "⚠ Alert!") {
    setPopupTitle(title);
    setPopupText(caption);
    popupCallback = () => {
      if (callback) callback();
      setOpenPopup(false);
    };
    setOpenPopup(true);
  }

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setLoading(true);
        let userTmp = (await functions.httpsCallable("getUser")({ email: user.email })).data.user;
        if (userTmp) {
          setLoginError("");
          setUser(userTmp);
          setStationIds(userTmp.stations);
          setStation(userTmp.stations?.[0] ?? {});
          await db.initStations(firestore, dbchangeStations, userTmp.stations?.[0].ids);
          await db.initLogs(firestore, dbchangeLogs);
          await db.initUsers(firestore, (data) => setUsers(data), station?.ids);
          window.addEventListener("resize", updateDimensions);
          setInit(true);
          setLoading(false);
          return () => window.removeEventListener("resize", updateDimensions);
        }
        else {
          handleLogout();
          setLoginError("There is no user record corresponding to this identifier.");
          setLoading(false);
        }
      }
      setInit(true);
    });
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (station && stations?.length > 0) {
      mergeStations();
    }
  }, [station, stations, logs, users]);

  return (
    <HashRouter>
      <React.Suspense fallback={loadingSpinner}>
        <Switch>
          <Route
            exact
            path="/Login"
            name="Login Page"
            render={(props) => (
              <Login
                {...props}
                loged={handleLogin}
                loginError={loginError}
                loading={loading}
              />
            )}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />

          {user && (
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <TheLayout
                  {...props}
                  version={process.env.REACT_APP_VERSION}
                  loading={loading}
                  texts={texts}
                  logout={handleLogout}
                  resetLock={resetLock}
                  rebootStation={rebootStation}
                  setStationConfig={setStationConfig}
                  openLock={openLock}
                  user={user}
                  updateUsers={updateUsers}
                  deleteUsers={deleteUsers}
                  addUsers={addUsers}
                  windowSize={windowSize}
                  stations={stations}
                  station={station}
                  mergedStations={mergedStations}
                  stationIds={stationIds}
                  setStation={changeStationSelected}
                  popup={popup}
                  hasAcces={hasAcces}
                />
              )}
            />
          )}
        </Switch>

        {!user && init && <Redirect to="/Login" />}

        {user && init && <Redirect to="/" />}
      </React.Suspense>

      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{popupTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {popupText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)} color="primary">
            CANCEL
          </Button>
          <Button onClick={popupCallback} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </HashRouter>
  );
}
